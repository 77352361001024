<template>
  <div class="login">
    <div v-if="!user">
      <aad-title>Login</aad-title>
      <div class="form">
        <div class="alert alert-danger my-2" role="alert" v-if="error">
          Error logging in. Please try again.
        </div>
        <div
          class="alert alert-warning my-2"
          role="alert"
          v-if="redirect && pageName != 'Logout'"
        >
          You need to be logged in to access {{ pageName }}.
        </div>
        <div class="alert alert-warning my-2" role="alert" v-if="redirect && pageName == 'Logout'">
          You have been successfully logged out. Have a great day!
        </div>
        <div class="form-group">
          <label for="username">Reg Code</label>
          <input
            v-model="regCode"
            type="text"
            name="regCode"
            class="input form-control"
          />
        </div>
        <div class="form-group">
          <label for="username">Last Name</label>
          <input
            v-model="lastName"
            type="text"
            name="lastName"
            class="input form-control"
          />
        </div>
        <button @click="login" class="btn btn-primary-solid">Login</button>
      </div>
    </div>

    <div v-else>
      <aad-title>Log Out</aad-title>
      <div class="form">
        <div class="alert alert-danger my-2" role="alert" v-if="error">
          Error logging out. Please try again.
        </div>
        <p>You are currently logged in as {{ user.name }}</p>
        <button @click="logout" class="btn btn-primary-solid">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      regCode: "",
      lastName: "",
      error: undefined,
    };
  },
  metaInfo: {
    title: "Log In",
  },
  methods: {
    login() {
      (this.error = undefined),
        this.$store
          .dispatch("login", {
            regCode: this.regCode,
            lastName: this.lastName,
          })
          .then(() => this.$router.push(this.$route.query.redirect || "/"))
          .catch((err) => {
            this.error = err;

            console.log(err);
          });
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    ...mapState(["user"]),
    redirect: function () {
      return this.$route.query.redirect;
    },
    pageName: function () {
      return this.$route.query.name;
    },
  },
};
</script>
